<template>
    <b-card>

        <!-- form -->
        <b-form>
            <b-row>
                <b-col sm="6">
                    <b-form-group
                        label="Nombre(s)"
                        label-for="account-name">
                        <b-form-input
                            v-model="optionsLocal.names"
                            name="names"
                            placeholder="Nombre(s)"
                        />
                    </b-form-group>
                </b-col>
                <b-col sm="6">
                    <b-form-group
                        label="Apellido(s)"
                        label-for="account-username"
                    >
                        <b-form-input
                            v-model="optionsLocal.surnames"
                            placeholder="Apellido(s)"
                            name="surnames"
                        />
                    </b-form-group>
                </b-col>
                <b-col sm="6">
                    <b-form-group
                        label="Correo electrónico"
                        label-for="account-e-mail">
                        <b-form-input
                            v-model="optionsLocal.email"
                            name="email"
                            placeholder="Correo electrónico"
                        />

                    </b-form-group>
                </b-col>
                <b-col sm="6">
                    <b-form-group
                        label="Teléfono"
                        label-for="account-phone">
                        <b-form-input
                            v-model="optionsLocal.phone"
                            name="phone"
                            placeholder="Teléfono"
                        />

                    </b-form-group>
                </b-col>

                <b-col cols="12">
                    <b-button
                        @click="updateGeneralInformation"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="mr-1">
                        Guardar cambios
                    </b-button>
                </b-col>
            </b-row>
        </b-form>
    </b-card>
</template>

<script>

    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import { useToast } from 'vue-toastification/composition';
    import Ripple from 'vue-ripple-directive';
    import axios from '@axios';

    export default {
        directives: {
            Ripple
        },
        props: {
            generalData: {
                type: Object,
                default: () => {}
            }
        },
        data() {
            return {
                optionsLocal: this.generalData,
                profileFile: null
            }
        },
        methods: {
            resetForm() {
                this.optionsLocal = this.generalData;
            }
        },
        setup(props) {

            // Use toast
            const toast = useToast();

            const updateGeneralInformation = () => {
                let generalData = {
                    ...props.generalData
                };

                let formData = new FormData();
                
                formData.append('names', generalData.names);
                formData.append('surnames', generalData.surnames);
                formData.append("email", generalData.email);
                formData.append("phone", generalData.phone);

                axios.put('/auth/update/general-information', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
                    .then( (response) => {

                        let localStorageUserData = JSON.parse(localStorage.getItem('userData'));

                        let data = {
                            ...localStorageUserData,
                            ...response.data.data
                        };

                        localStorage.setItem('userData', JSON.stringify(data));

                        toast({
                            component: ToastificationContent,
                            props: {
                                title: response.data.message,
                                icon: 'CheckIcon',
                                variant: 'success'
                            }
                        });

                        setTimeout(() => {
                            window.location.reload();
                        }, 3000);

                    })
                    .catch( (err) => {
                        const message = err.response.data.message ? err.response.data.message : 'Error al actualizar tu información general.';
                        
                        toast({
                            component: ToastificationContent,
                            props: {
                                title: message,
                                icon: 'AlertTriangleIcon',
                                variant: 'danger'
                            }
                        });

                    });
            };

            return {
                updateGeneralInformation
            }
        }
    }

</script>

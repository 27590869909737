<template>
    <b-card>
        <!-- form -->
        <validation-observer ref="updatePasswordForm">
            <b-form>
              <b-row>
                <!-- old password -->
                <b-col md="6">
                  <b-form-group
                    label="Contraseña actual"
                    label-for="account-old-password"
                  >
                      <validation-provider
                          #default="{ errors }"
                          name="contraseña actual"
                          vid="PasswordOld"
                          rules="required"
                      >
                        <b-input-group class="input-group-merge">
                      <b-form-input
                        id="account-old-password"
                        ref="passwordValueOld"
                        v-model="passwordValueOld2"
                        name="old-password"
                        :state="errors.length > 0 ? false:null"
                        :type="passwordFieldTypeOld"
                        placeholder="Contraseña actual"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordToggleIconOld"
                          class="cursor-pointer"
                          @click="togglePasswordOld"
                        />
                      </b-input-group-append>
                    </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                  </b-form-group>
                </b-col>
                <!--/ old password -->
              </b-row>
              <b-row>
                <!-- new password -->
                <b-col md="6">
                  <b-form-group
                    label-for="account-new-password"
                    label="Contraseña nueva"
                  >
                      <validation-provider
                          #default="{ errors }"
                          name="contraseña nueva"
                          vid="Password"
                          rules="required|password"
                      >
                          <b-input-group class="input-group-merge">
                              <b-form-input
                                  id="account-new-password"
                                  :state="errors.length > 0 ? false:null"
                                  ref="newPasswordValue"
                                  v-model="newPasswordValue2"
                                  :type="passwordFieldTypeNew"
                                  name="new-password"
                                  placeholder="********"
                              />
                              <b-input-group-append is-text>
                                  <feather-icon
                                      :icon="passwordToggleIconNew"
                                      class="cursor-pointer"
                                      @click="togglePasswordNew"
                                  />
                              </b-input-group-append>
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                  </b-form-group>
                </b-col>
                <!--/ new password -->

                <!-- retype password -->
                <b-col md="6">
                  <b-form-group
                    label-for="account-retype-new-password"
                    label="Reescriba nueva contraseña"
                  >
                      <validation-provider
                          #default="{ errors }"
                          name="confirmar contraseña"
                          rules="required|confirmed:Password"
                      >
                    <b-input-group class="input-group-merge">
                      <b-form-input
                        id="account-retype-new-password"
                        ref="RetypePassword"
                        v-model="RetypePassword2"
                        :type="passwordFieldTypeRetype"
                        :state="errors.length > 0 ? false:null"
                        name="retype-password"
                        placeholder="********"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordToggleIconRetype"
                          class="cursor-pointer"
                          @click="togglePasswordRetype"
                        />
                      </b-input-group-append>
                    </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                  </b-form-group>
                </b-col>
                <!--/ retype password -->

                <!-- buttons -->
                <b-col cols="12">
                  <b-button
                      type="submit"
                      @click.prevent="validationForm"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-1"
                  >
                    Guardar cambios
                  </b-button>
                </b-col>
                <!--/ buttons -->
              </b-row>
            </b-form>
        </validation-observer>
    </b-card>
</template>

<script>

    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { required, email, confirmed, password } from '@validations';
    import { useToast } from 'vue-toastification/composition';
    import { ref } from '@vue/composition-api';
    import Ripple from 'vue-ripple-directive';
    import axios from '@axios';

    export default {
        components: {
            ValidationProvider,
            ValidationObserver
        },
        directives: {
            Ripple
        },
        data() {
            return {
                required,
                password,
                email,
                confirmed,

                passwordValueOld2: '',
                newPasswordValue2: '',
                RetypePassword2: '',
                passwordFieldTypeOld: 'password',
                passwordFieldTypeNew: 'password',
                passwordFieldTypeRetype: 'password'
            }
        },
        computed: {
            passwordToggleIconOld() {
                return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
            },
            passwordToggleIconNew() {
                return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
            },
            passwordToggleIconRetype() {
                return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
            }
        },
        methods: {
            togglePasswordOld() {
                this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
            },
            togglePasswordNew() {
                this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
            },
            togglePasswordRetype() {
                this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
            }
        },
        setup() {

            // Use toast
            const toast = useToast();

            const updatePasswordForm = ref(null);
            const passwordValueOld = ref('');
            const newPasswordValue = ref('');

            const validationForm = () => {

                updatePasswordForm.value.validate().then(success => {
                    if (success) {

                        let data = {
                            oldPassword: passwordValueOld.value.$el.value,
                            password: newPasswordValue.value.$el.value
                        };

                        axios.put('/auth/update/password', data)
                            .then((response) => {
                                toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: response.data.message,
                                        icon: 'CheckIcon',
                                        variant: 'success'
                                    }
                                });
                                setTimeout(() => {
                                    window.location.reload();
                                }, 3000);
                            })
                            .catch((err) => {
                                let message = err.response.data.message ? err.response.data.message : 'Error al actualizar tu contraseña.';
                                toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: message,
                                        icon: 'AlertTriangleIcon',
                                        variant: 'danger'
                                    }
                                });
                            });
                    }
                });
            };

            return {
                updatePasswordForm,
                passwordValueOld,
                newPasswordValue,
                validationForm
            }
        }
    }

</script>
